<template>
    <div class="btn-group" role="group">
        <button
            v-for="(buttonIcon, buttonMode) in modes"
            type="button"
            :class="['btn', 'btn-sm', mode === buttonMode ? 'btn-primary' : 'btn-outline-primary']"
            @click="mode = buttonMode"
        >
            <Icon :v="buttonIcon" />
            <T>mode.{{ buttonMode }}</T>
        </button>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import useDark from '../composables/useDark.ts';

export default defineComponent({
    setup() {
        const { detectDark, isDark, getMode, setMode } = useDark();
        return {
            detectDark,
            isDark,
            getMode,
            setMode,
        };
    },
    data() {
        return {
            mode: 'automatic' as Mode,
            modes: {
                light: 'sun',
                automatic: 'eclipse',
                dark: 'moon',
            },
        };
    },
    watch: {
        mode() {
            this.setMode(this.mode);
            this.isDark = this.detectDark();
        },
    },
    mounted() {
        this.mode = this.getMode();

        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => this.isDark = this.detectDark());
    },
});
</script>
